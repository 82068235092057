"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.qnaIndexer = void 0;

var qnaUtil_1 = require("./utils/qnaUtil");

var help_1 = require("./utils/help");

var fileExtensions_1 = require("./utils/fileExtensions");

function index(files) {
  if (files.length === 0) return [];
  var qnaFiles = [];

  for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
    var file = files_1[_i];
    var name_1 = file.name,
        content = file.content;

    if (name_1.endsWith(fileExtensions_1.FileExtensions.QnA)) {
      var id = help_1.getBaseName(name_1, fileExtensions_1.FileExtensions.QnA);
      var data = qnaUtil_1.parse(id, content);
      qnaFiles.push(data);
    }
  }

  return qnaFiles;
}

exports.qnaIndexer = {
  index: index,
  parse: qnaUtil_1.parse
};